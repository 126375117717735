const OldSystemFrame = () => import('@/views/OldSystemFrame');
const CustomersDashboard = () => import('@/views/customers/Customers');
const CustomersListAll = () => import('@/views/customers/list-all/Customers');
const CustomerDetails = () => import('@/views/customers/details/Details');
const CustomersProfile = () => import('@/views/customers/profile/Profile');
const CustomersData = () => import('@/views/customers/data/Data');
const EducationData = () => import('@/views/customers/education/Education');

import ConsultationRoutes from '@/views/customers/consultation/routes.js';
import FunlearnRoutes from '@/views/customers/funlearn/routes.js';
import SettingsRoutes from '@/views/customers/settings/routes.js';

export default [
  {
    path: 'customers',
    name: 'Kundenberater-Cockpit',
    redirect: '/customers/dashboard/0',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'dashboard',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'all',
            component: CustomersListAll,
          },
          {
            path: ':type',
            component: CustomersDashboard,
          },
          {
            path: ':type/details/:id/:education',
            component: CustomerDetails,
            children: [
              {
                path: 'profile',
                name: 'profile',
                meta: {
                  label: 'Profil'
                },
                component: CustomersProfile,
              },
              {
                path: 'data/:referral?',
                name: 'data',
                meta: {
                  label: 'Daten'
                },
                component: CustomersData,
              },
              {
                path: 'education',
                name: 'education',
                meta: {
                  label: 'Ausbildung'
                },
                component: EducationData,
              },
            ]
          },
        ]
      },
      {
        path: 'price',
        component: OldSystemFrame,
        props:{
          url: '/login/pricelist'
        }
      },
      {
        path: 'abc',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=347'
        }
      },
      ...ConsultationRoutes,
      ...FunlearnRoutes,
      ...SettingsRoutes,
    ]
  },
]
