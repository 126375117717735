const Status = () => import('@/views/customers/settings/status/Status');
const OldSystemFrame = () => import('@/views/OldSystemFrame');

export default [
  {
    path: 'settings',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'import',
        component: OldSystemFrame,
        props: {
          url: '/login/templates/content.php?id=287&func=importCustomers'
        }
      },
      {
        path: 'vogel-import',
        component: OldSystemFrame,
        props: {
          url: '/login/templates/content.php?id=287&func=importCustomers&text=vogel'
        }
      },
      {
        path: 'status',
        component: Status,
      },
      {
        path: 'emails',
        component: OldSystemFrame,
        props: {
          url: '/login/templates/content.php?id=287&func=emailTemplates'
        }
      },
      {
        path: 'general',
        component: OldSystemFrame,
        props: {
          url: '/login/templates/content.php?id=287&func=fahrschuelerSettings'
        }
      },
    ]
  },
]
